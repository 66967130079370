import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["appearance", "heading", "description", "image", "assetUrl"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { AppearanceProvider, useAppearance } from '@segment/matcha/foundations/AppearanceContext';
import Image from '@segment/matcha/components/Image';
import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText';
import EmailCaptureForm from '@segment/templates/Forms/EmailCaptureForm';
import { customDisclaimer } from './data';
import * as views from './ContentDownloadForm.views';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var SUCCESS_MESSAGE = "Thank you for downloading this content. We've also sent a copy to your inbox.";
var BUTTON_TEXT = "Download Now";
export var ContentDownloadForm = function ContentDownloadForm(_ref) {
  var appearance = _ref.appearance,
      heading = _ref.heading,
      description = _ref.description,
      image = _ref.image,
      assetUrl = _ref.assetUrl,
      rest = _objectWithoutProperties(_ref, _excluded);

  var formAppearance = useAppearance(appearance);

  var onSubmitHandler = function onSubmitHandler() {
    if (assetUrl) {
      window.open(assetUrl, "_blank");
    }
  };

  return _jsxs(AppearanceProvider, {
    appearance: formAppearance,
    children: [_jsx(views.StyledDivider, {}), _jsxs(CopyTextBase, {
      children: [_jsx(CopyText.Heading, {
        children: heading
      }), _jsx(CopyText.Description, {
        variant: "pMedium",
        children: description
      })]
    }), _jsxs(views.Wrapper, {
      children: [image && _jsx(views.FlexContainer, {
        children: _jsx(Image, {
          src: image,
          alt: "Download asset image for ".concat(heading)
        })
      }), _jsx(views.FlexContainer, {
        children: _jsx(EmailCaptureForm, _objectSpread({
          onSubmit: onSubmitHandler,
          customDisclaimer: customDisclaimer,
          buttonText: BUTTON_TEXT,
          successMessageDescription: SUCCESS_MESSAGE
        }, rest))
      })]
    }), _jsx(views.StyledDivider, {})]
  });
};
export default ContentDownloadForm;