import { BLOCKS, INLINES } from '@contentful/rich-text-types';
var data = {
  content: [{
    data: {},
    content: [{
      data: {},
      marks: [],
      value: "For information about how Segment handles your personal data, please see our ",
      nodeType: "text"
    }, {
      data: {
        uri: "https://www.twilio.com/legal/privacy/"
      },
      content: [{
        data: {},
        marks: [],
        value: "privacy policy",
        nodeType: "text"
      }],
      nodeType: INLINES.HYPERLINK
    }, {
      data: {},
      marks: [],
      value: ".",
      nodeType: "text"
    }],
    nodeType: BLOCKS.PARAGRAPH
  }],
  data: {},
  nodeType: BLOCKS.DOCUMENT
};
export default data;