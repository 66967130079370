import { BLOCKS, INLINES } from '@contentful/rich-text-types';
export var customDisclaimer = {
  content: [{
    data: {},
    content: [{
      data: {},
      marks: [],
      value: "We use your information according to our ",
      nodeType: "text"
    }, {
      data: {
        uri: "https://www.twilio.com/legal/privacy/"
      },
      content: [{
        data: {},
        marks: [],
        value: "Privacy Policy",
        nodeType: "text"
      }],
      nodeType: INLINES.HYPERLINK
    }, {
      data: {},
      marks: [],
      value: ". You can update your preferences at any time.",
      nodeType: "text"
    }],
    nodeType: BLOCKS.PARAGRAPH
  }],
  data: {},
  nodeType: BLOCKS.DOCUMENT
};