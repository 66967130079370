import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["variant", "tag", "color", "children", "appearance", "tokens"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useAppearance } from '@segment/matcha/foundations/AppearanceContext';
import Typography from '@segment/matcha/components/Typography';
import { withDesignTokens } from '@segment/matcha/useDesignTokens';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

var ColoredTypography = function ColoredTypography(_ref) {
  var variant = _ref.variant,
      tag = _ref.tag,
      color = _ref.color,
      children = _ref.children,
      externalAppearance = _ref.appearance,
      tokens = _ref.tokens,
      rest = _objectWithoutProperties(_ref, _excluded);

  var appearance = useAppearance(externalAppearance);
  var appearanceColor = tag === "p" ? tokens.paragraphColor : tokens.defaultColor;
  return _jsx(Typography, _objectSpread(_objectSpread({
    tokens: {
      color: color || appearanceColor
    },
    variant: variant,
    tag: tag,
    appearance: appearance
  }, rest), {}, {
    children: children
  }));
};

export default withDesignTokens(ColoredTypography, function () {
  return {};
}, {
  light: function light(theme) {
    return {
      defaultColor: theme.palette.brand.darkAcai[700],
      paragraphColor: theme.palette.brand.darkAcai[500]
    };
  },
  dark: function dark(theme) {
    return {
      defaultColor: theme.palette.neutrals.white[100],
      paragraphColor: theme.palette.brand.darkAcai[400]
    };
  },
  twilioLight: function twilioLight(theme) {
    return {
      defaultColor: theme.palette.twilio.gray[100],
      paragraphColor: theme.palette.twilio.gray[100]
    };
  },
  twilioDark: function twilioDark(theme) {
    return {
      defaultColor: theme.palette.twilio.gray[100],
      paragraphColor: theme.palette.twilio.gray[100]
    };
  },
  cdpWeek2023: function cdpWeek2023(theme) {
    return {
      defaultColor: theme.palette.cdpWeek2023.gray[10],
      paragraphColor: theme.palette.cdpWeek2023.gray[10]
    };
  }
});