import { BLOCKS, INLINES } from '@contentful/rich-text-types';
export var richTextDisclaimer = function richTextDisclaimer() {
  var resourceType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "guide";
  return {
    data: {},
    content: [{
      data: {},
      content: [{
        data: {},
        marks: [],
        value: "We\u2019ll share a copy of this ".concat(resourceType, " and send you content and updates about Twilio Segment\u2019s products as we continue to build the world\u2019s leading CDP. We use your information according to our "),
        nodeType: "text"
      }, {
        data: {
          uri: "https://www.twilio.com/legal/privacy/"
        },
        content: [{
          data: {},
          marks: [],
          value: "privacy policy",
          nodeType: "text"
        }],
        nodeType: INLINES.HYPERLINK
      }, {
        data: {},
        marks: [],
        value: ".",
        nodeType: "text"
      }, {
        data: {},
        marks: [],
        value: " You can update your preferences at any time.",
        nodeType: "text"
      }],
      nodeType: BLOCKS.PARAGRAPH
    }],
    nodeType: BLOCKS.DOCUMENT
  };
};