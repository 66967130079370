import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children", "tokens"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { withDesignTokens } from '@segment/matcha/useDesignTokens';
import * as views from './Hyperlink.views';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

var BaseHyperlink = function BaseHyperlink(_ref) {
  var children = _ref.children,
      tokens = _ref.tokens,
      rest = _objectWithoutProperties(_ref, _excluded);

  var linkColor = tokens.color && {
    color: tokens.color
  };
  return _jsx(views.StyledHyperlink, _objectSpread(_objectSpread(_objectSpread({}, rest), linkColor), {}, {
    children: children
  }));
};

var Hyperlink = withDesignTokens(BaseHyperlink, function () {
  return {};
}, {
  light: function light() {
    return {
      color: undefined
    };
  },
  dark: function dark() {
    return {
      color: undefined
    };
  },
  twilioLight: function twilioLight(theme) {
    return {
      color: theme.palette.twilio.green[70]
    };
  },
  twilioDark: function twilioDark(theme) {
    return {
      color: theme.palette.twilio.green[70]
    };
  },
  cdpWeek2023: function cdpWeek2023(theme) {
    return {
      color: theme.palette.twilio.green[70]
    };
  }
});
export default Hyperlink;