import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import RichText from '@segment/matcha/recipes/RichText';
import { withDesignTokens } from '@segment/matcha/useDesignTokens';
import * as views from './Disclaimer.views';
import defaultData from './data';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

var renderDisclaimer = function renderDisclaimer(color) {
  var _ref;

  return _ref = {}, _defineProperty(_ref, BLOCKS.PARAGRAPH, function (_, children) {
    return _jsx(views.FormCaption, {
      tag: "p",
      variant: "pMedium",
      tokens: {
        color: color,
        linkColor: color,
        linkHoverColor: color
      },
      children: children
    });
  }), _defineProperty(_ref, INLINES.HYPERLINK, function (node, children) {
    return _jsx(views.FormCaptionLink, {
      size: "small",
      target: "_self",
      tag: "a",
      href: node.data.uri,
      color: color,
      children: children
    });
  }), _ref;
};

var BaseDisclaimer = function BaseDisclaimer(_ref2) {
  var tokens = _ref2.tokens,
      _ref2$data = _ref2.data,
      data = _ref2$data === void 0 ? defaultData : _ref2$data;
  return _jsx(RichText, {
    data: data,
    options: {
      renderNode: renderDisclaimer(tokens.color)
    }
  });
};

export var Disclaimer = withDesignTokens(BaseDisclaimer, function () {
  return {};
}, {
  dark: function dark(theme) {
    return {
      color: theme.palette.brand.darkAcai[400]
    };
  },
  light: function light(theme) {
    return {
      color: theme.palette.brand.darkAcai[500]
    };
  },
  twilioLight: function twilioLight(theme) {
    return {
      color: theme.palette.twilio.gray[60]
    };
  },
  twilioDark: function twilioDark(theme) {
    return {
      color: theme.palette.twilio.gray[10]
    };
  },
  cdpWeek2023: function cdpWeek2023(theme) {
    return {
      color: theme.palette.twilio.gray[40]
    };
  }
});