import FigureCompBase, * as FigureComp from '@segment/matcha/components/Figure';
import * as views from './Figure.views';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";

var Figure = function Figure(_ref) {
  var src = _ref.src,
      width = _ref.width,
      height = _ref.height,
      title = _ref.title,
      loader = _ref.loader,
      caption = _ref.caption;
  return _jsx(views.FigureWrapper, {
    children: _jsxs(FigureCompBase, {
      children: [_jsx(FigureComp.Image, {
        src: src,
        width: width,
        height: height,
        alt: title || "",
        loader: loader
      }), caption && _jsx(FigureComp.Caption, {
        children: caption
      })]
    })
  });
};

export default Figure;